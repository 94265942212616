import React from 'react';

function Logo() {
  return (
    <svg className="logo" width="152" height="24" viewBox="0 0 152 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.46969C0 3.85581 4.34838 0.302246 9.20148 0.302246C14.1905 0.302246 16.7723 3.26985 17.4712 6.12405L14.171 7.14475C13.7246 5.46248 12.3269 3.59119 9.20148 3.59119C6.50315 3.59119 3.64953 5.48138 3.64953 9.46969C3.64953 13.2123 6.34785 15.2726 9.25971 15.2726C12.3463 15.2726 13.841 13.3068 14.3263 11.6812L17.607 12.6452C16.9276 15.3482 14.3263 18.6182 9.25971 18.6182C4.21249 18.6182 0 15.0836 0 9.46969Z" fill="black"/>
      <path d="M27.7017 9.31865C27.3135 9.24304 26.9834 9.22414 26.6923 9.22414C24.9646 9.22414 23.4504 10.0369 23.4504 12.6643V18.2404H20.0532L20.0532 6.04861L23.3339 6.04861V7.8632C24.091 6.25653 25.8381 5.9541 26.9058 5.9541C27.1776 5.9541 27.4493 5.973 27.7017 6.01081V9.31865Z" fill="black"/>
      <path d="M33.1569 11.2279L36.2434 10.7743C36.9617 10.6798 37.1946 10.3206 37.1946 9.90479C37.1946 9.0353 36.5152 8.31703 35.0787 8.31703C33.6033 8.31703 32.788 9.24322 32.691 10.3017L29.682 9.67797C29.8762 7.76887 31.7009 5.65186 35.0593 5.65186C39.0388 5.65186 40.5142 7.82558 40.5142 10.2828V16.2936C40.5142 16.9363 40.5918 17.8058 40.6695 18.2216H37.5635C37.4858 17.9003 37.4276 17.2387 37.4276 16.7662C36.787 17.7302 35.5834 18.5808 33.7392 18.5808C31.0603 18.5808 29.4297 16.8229 29.4297 14.9138C29.4297 12.7401 31.0797 11.5304 33.1569 11.2279ZM37.1946 13.326V12.7779L34.3604 13.1937C33.4869 13.326 32.8074 13.7797 32.8074 14.7248C32.8074 15.4431 33.351 16.1424 34.4381 16.1424C35.8746 16.1424 37.1946 15.4809 37.1946 13.326Z" fill="black"/>
      <path d="M45.599 0C46.7637 0 47.6955 0.926195 47.6955 2.06031C47.6955 3.15662 46.7831 4.06392 45.599 4.06392C44.4536 4.06392 43.5024 3.13772 43.5024 2.06031C43.5024 0.926195 44.4536 0 45.599 0ZM43.9101 6.04862L47.3073 6.04862V18.2593H43.9101V6.04862Z" fill="black"/>
      <path d="M53.093 18.1267C53.326 19.4687 54.4131 20.4327 56.0243 20.4327C58.1597 20.4327 59.3632 19.3931 59.3632 17.0304V16.142C58.8585 16.9359 57.6744 17.7109 55.869 17.7109C52.5301 17.7109 50.0259 15.1969 50.0259 11.7756C50.0259 8.54341 52.4136 5.82153 55.869 5.82153C57.8491 5.82153 58.9944 6.67212 59.4409 7.4849V6.04836H62.7022V16.9359C62.7022 20.2815 60.8386 23.3436 56.1214 23.3436C52.6854 23.3436 50.3947 21.2644 50.0259 18.9206L53.093 18.1267ZM59.4021 11.7756C59.4021 9.88545 58.082 8.69463 56.4514 8.69463C54.7625 8.69463 53.4425 9.88545 53.4425 11.7756C53.4425 13.6847 54.6849 14.8756 56.4514 14.8756C58.1791 14.8756 59.4021 13.6658 59.4021 11.7756Z" fill="black"/>
      <path d="M92.8884 0.661377L96.4797 0.661377L91.4713 18.2591H87.8606L83.7257 5.7271L79.6103 18.2591H75.9414L70.9136 0.661377L74.6408 0.661377L77.9603 12.7775L81.9592 0.661377L85.5893 0.661377L89.6271 12.8153L92.8884 0.661377Z" fill="black"/>
      <path d="M100.304 11.2279L103.391 10.7743C104.109 10.6798 104.342 10.3206 104.342 9.90479C104.342 9.0353 103.662 8.31703 102.226 8.31703C100.75 8.31703 99.9352 9.24322 99.8381 10.3017L96.8292 9.67797C97.0233 7.76887 98.8481 5.65186 102.206 5.65186C106.186 5.65186 107.661 7.82558 107.661 10.2828V16.2936C107.661 16.9363 107.739 17.8058 107.817 18.2216H104.711C104.633 17.9003 104.575 17.2387 104.575 16.7662C103.934 17.7302 102.75 18.5808 100.886 18.5808C98.2075 18.5808 96.5768 16.8229 96.5768 14.9138C96.5574 12.7401 98.2075 11.5304 100.304 11.2279ZM104.322 13.326V12.7779L101.488 13.1937C100.615 13.326 99.9352 13.7797 99.9352 14.7248C99.9352 15.4431 100.479 16.1424 101.566 16.1424C103.002 16.1424 104.322 15.4809 104.322 13.326Z" fill="black"/>
      <path d="M113.931 0.302246L110.534 0.302246V18.2591H113.931V0.302246Z" fill="black"/>
      <path d="M124.161 11.1898L129.286 18.2591L125.132 18.2591L121.832 13.5903L120.415 15.0269V18.2591H117.037V0.302246L120.415 0.302246V10.5849L124.744 6.04844L129.189 6.04844L124.161 11.1898Z" fill="black"/>
      <path d="M141.439 14.7626C140.798 16.8607 138.818 18.6375 135.731 18.6375C132.295 18.6375 129.267 16.237 129.267 12.1164C129.267 8.22256 132.218 5.6897 135.44 5.6897C139.303 5.6897 141.633 8.09025 141.633 12.0219C141.633 12.4944 141.575 12.9858 141.575 13.0426L132.606 13.0426C132.684 14.6492 134.081 15.8211 135.77 15.8211C137.343 15.8211 138.216 15.0462 138.624 13.9688L141.439 14.7626ZM138.274 10.7743C138.216 9.5646 137.401 8.37377 135.479 8.37377C133.713 8.37377 132.761 9.65911 132.684 10.7743L138.274 10.7743Z" fill="black"/>
      <path d="M151.436 9.31865C151.047 9.24304 150.717 9.22414 150.407 9.22414C148.679 9.22414 147.165 10.0369 147.165 12.6643V18.2404H143.787V6.04861L147.068 6.04861V7.8632C147.825 6.25653 149.572 5.9541 150.64 5.9541C150.911 5.9541 151.183 5.973 151.436 6.01081V9.31865Z" fill="black"/>
    </svg>
  )
}

export default Logo;
