export const theme = {
  colours: {
    black: "#000",
    white: "#fff",

    // Brand
    orange: "#FE754A",
    blue: "#5498FF",
    yellow: "#FFF850",
    green: "#65C879",
    grey: "#F7F7F6",
    grey2: "#929292",
    transparentGrey: "rgba(247, 247, 246, 0.93)",
    transparentGrey2: "rgba(196, 196, 196, 0.15)",
    xmas: "#FDC16B",

    // Accessibility and forms
    focus: "#4EF45F",
    error: "#FF3D00",
  },
  typography: {
    min: 16,
    max: 22,
    minScreen: 400,
    maxScreen: 1100,
    scale: {
      min: 1.125,
      max: 1.125,
    },
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (exp) => {
    return `
      font-size: ${
        theme.typography.min * Math.pow(theme.typography.scale.min, exp)
      }px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${
          theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        }px + (${
      theme.typography.max * Math.pow(theme.typography.scale.max, exp)
    } - ${
      theme.typography.min * Math.pow(theme.typography.scale.min, exp)
    })*(100vw - ${theme.typography.minScreen}px)/(${
      theme.typography.maxScreen
    } - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${
          theme.typography.max * Math.pow(theme.typography.scale.max, exp)
        }px;
      }
      `
  },
  breakpoints: {
    xs: "min-width: 400px",
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: "min-width: 1200px",
  },
}
