import React, { useState, useEffect } from "react"
import MenuOpen from "../../assets/menuOpen.inline.svg"
import MenuClosed from "../../assets/menuClosed.inline.svg"
import IconOwl from "../atoms/iconOwl"
import XmasIconOwl from "../atoms/XmasIconOwl"
import styled from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import AnimatedLogo from "../atoms/AnimatedLogo"
import { workList } from "../../data/workList"

const Holder = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`

const LogoRow = styled.div`
  filter: unset !important;
  filter: ${(props) =>
    props.invertHomeNav ? "invert(1) !important" : "unset"};
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-gap: 1rem;
  align-items: center;
`

const LogoHolder = styled.div`
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  width: 100%;
  a {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: ${(props) => props.theme.typography.maxScreen}px;
    svg {
      width: 100%;
      max-width: 7rem;
    }
  }
`

function Header(props) {
  const [clientLogo, setClientLogo] = useState(null)
  const path = props.location.pathname
  const is_home = props.location.pathname === "/"
  const [invertHomeNav, setInvertHomeNav] = useState(is_home)
  useEffect(() => {
    if (!is_home) {
      setInvertHomeNav(false)
    }
  }, [is_home])

  useEffect(() => {
    const project = workList.find((work) =>
      props.location.pathname.includes(work.slug)
    )
    if (project) {
      setClientLogo(project.logo)
    }
  }, [props.location])

  useEffect(() => {
    const onScroll = () => {
      let navOffset = 30
      if (window.screen.width < 992 && props.showNav) {
        // big nav
        navOffset = -60
      } else if (props.showNav) {
        // small nav
        navOffset = -20
      } else if (window.screen.width < 992) {
        // small screen, closed nav
        navOffset = 20
      }
      if (window.pageYOffset < window.innerHeight - navOffset) {
        setInvertHomeNav(true)
      } else {
        setInvertHomeNav(false)
      }
    }
    if (is_home) {
      window.addEventListener("scroll", onScroll)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [props.showNav, is_home])

  return (
    <Holder>
      <LogoRow className="logo-row" invertHomeNav={invertHomeNav}>
        {path.includes("christmas") || path.includes("card") ? (
          <div id="xmas-owl">
            <XmasIconOwl />
          </div>
        ) : (
          <div id="owl">
            <IconOwl />
          </div>
        )}
        <LogoHolder>
          <Link to="/">
            <AnimatedLogo clientLogo={clientLogo} />
          </Link>
        </LogoHolder>
        <button
          className="icon"
          onClick={() => props.clickHandler()}
          role="menu button"
        >
          {props.showNav && <MenuOpen />}
          {!props.showNav && <MenuClosed />}
        </button>
      </LogoRow>
    </Holder>
  )
}

Header.propTypes = {
  clickHandler: PropTypes.func,
  location: PropTypes.object.isRequired,
  showNav: PropTypes.bool.isRequired,
}

export default Header
