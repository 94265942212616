import React from "react"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import { PropTypes } from "prop-types"
import { CSSTransition } from "react-transition-group"

const Holder = styled.nav`
  overflow: hidden;
  position: relative;

  &.nav-appear,
  &.nav-enter {
    height: 0;
    ul {
      transform: translateY(-100%);
    }
  }
  &.nav-appear-active,
  &.nav-appear-done,
  &.nav-enter-active,
  &.nav-enter-done {
    height: 5rem;
    transition: height 250ms;
    @media (${(props) => props.theme.breakpoints.lg}) {
      height: 2rem;
    }
    ul {
      transform: translateY(0);
      transition: transform 250ms;
    }
  }
  &.nav-exit {
    height: 5rem;
    @media (${(props) => props.theme.breakpoints.lg}) {
      height: 2rem;
    }
    ul {
      transform: translateY(0);
    }
  }
  &.nav-exit-active {
    height: 0;
    transition: height 250ms;
    ul {
      transform: translateY(-100%);
      transition: transform 250ms;
    }
  }

  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
`

const Item = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  /* line-height: 5rem; */
  text-align: center;
  font-size: 13px;
  @media (${(props) => props.theme.breakpoints.sm}) {
    font-size: ${(props) => props.theme.fluidType(-2)};
  }
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${(props) => props.bg};
  @media (${(props) => props.theme.breakpoints.lg}) {
    line-height: 2rem;
  }
  a {
    text-decoration: none;
    display: block;
  }
`

function Navigation({ theme, showNav }) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={showNav}
      timeout={250}
      classNames="nav"
    >
      <Holder>
        <ul>
          <Link to="/work/">
            <Item bg={theme.colours.orange}>Work</Item>
          </Link>
          <Link to="/services/">
            <Item bg={theme.colours.yellow}>services</Item>
          </Link>
          <Link to="/about/">
            <Item bg={theme.colours.blue}>About</Item>
          </Link>
          <Link to="/journal/">
            <Item bg={theme.colours.green}>Journal</Item>
          </Link>
        </ul>
      </Holder>
    </CSSTransition>
  )
}

Navigation.propTypes = {
  theme: PropTypes.object,
  showNav: PropTypes.bool.isRequired,
}

export default withTheme(Navigation)
