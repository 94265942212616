import React from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import styled from "styled-components"

//This variable will be responsible for our animation duration
const timeout = 2000
const timeoutUnit = timeout / 4

const TransitionHolder = styled.div`
  position: relative;
`

const PageHolder = styled.div`
  width: 100%;
  &.page-appear,
  &.page-enter {
    position: absolute;
    top: 0;
    left: 0;
    .content-holder,
    .splash-content,
    .client-logo,
    .globe-holder,
    .logo-row {
      opacity: 0;
    }
    .splash-bg {
      height: 0;
    }
  }
  &.page-appear-active,
  &.page-appear-done,
  &.page-enter-active,
  &.page-enter-done {
    .content-holder,
    .splash-content,
    .client-logo,
    .logo-row,
    .globe-holder {
      opacity: 1;
      transition: opacity ${timeoutUnit}ms ${timeoutUnit * 3}ms;
    }
    #xmas-owl {
      opacity: ${(props) => (props.isChristmas ? 1 : 0)};
      transition: opacity ${timeoutUnit}ms ${timeoutUnit * 3}ms;
    }
    #owl {
      opacity: ${(props) => (props.isChristmas ? 0 : 1)};
    }
    .splash-bg {
      height: 100%;
      transition: height ${timeoutUnit}ms ${timeoutUnit * 2}ms;
    }
  }
  &.page-exit {
    .content-holder,
    .splash-content,
    .client-logo,
    .logo-row,
    .globe-holder {
      opacity: 1;
    }
    #xmas-owl {
      opacity: ${(props) => (props.isChristmas ? 1 : 0)};
    }
    #owl {
      opacity: ${(props) => (props.isChristmas ? 0 : 1)};
    }
    .splash-bg {
      height: 100%;
    }
  }
  &.page-exit-active {
    .content-holder,
    .splash-content,
    .client-logo,
    .globe-holder,
    .logo-row {
      opacity: 0;
      transition: opacity ${timeoutUnit}ms;
    }
    #xmas-owl {
      opacity: ${(props) => (props.isChristmas ? 0 : 1)};
    }
    #owl {
      opacity: ${(props) => (props.isChristmas ? 1 : 0)};
    }
    .splash-bg {
      height: 0;
      transition: height ${timeoutUnit}ms ${timeoutUnit}ms;
    }
  }
`

class Transition extends React.PureComponent {
  render() {
    //Destructuring props to avoid garbage this.props... in return statement
    const { children, location } = this.props
    const path = this.props.location.pathname
    const isChristmas = path.includes("christmas") || path.includes("card")

    return (
      //Using TransitionGroup and CSSTransition which are both
      //coming from  'react-transition-group' and are required for transitions to work
      <TransitionHolder>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            timeout={timeout}
            classNames="page"
          >
            <PageHolder isChristmas={isChristmas}>{children}</PageHolder>
          </CSSTransition>
        </TransitionGroup>
      </TransitionHolder>
    )
  }
}

export default Transition
