export const workList = [
  {
    title: "Designing for Youth",
    client: "TTC Labs",
    id: "dfy-ttc",
    slug: "/designing-for-youth-ttc",
    featuredImage: "ttc-youth-9.jpg",
    logo: "ttc-youth/ttc-meta-logo.png",
  },
  {
    title: "Privacy Enhancing Technologies",
    client: "Meta",
    id: "pet-meta",
    slug: "/privacy-tech-meta",
    featuredImage: "privacy-tech-6.jpg",
    logo: "meta-logo.png",
  },
  {
    title: "Connecting Colleagues",
    client: "Craig Walker & partners",
    id: "walker-talker",
    slug: "/connecting-colleagues",
    featuredImage: "pro-x-002.png",
    logo: "pro-x-logos-main.png",
  },
  {
    title: "Hep-C Service Framework",
    client: "NSW Health — SESLHD",
    id: "nsw-health",
    slug: "/nsw-health",
    featuredImage: "nsw-health-new-1.jpg",
    logo: "bw-logo-nsw-health-seslhd.png",
  },
  {
    title: "Website Content Strategy & Design",
    client: "Facebook TTC Labs",
    id: "facebook",
    slug: "/facebook",
    featuredImage: "ttc-new-00001.jpg",
    logo: "bw-logo-fb.png",
  },
  {
    title: "Neighbourhood Welcome Service",
    client: "Australia Post",
    id: "aus-post",
    slug: "/australia-post",
    featuredImage: "aus-post-new-00001.jpg",
    logo: "bw-logo-aus-post.png",
  },
  {
    title: "Broadway Guest Experience",
    client: "Mirvac",
    id: "mirvac",
    slug: "/mirvac",
    featuredImage: "broadway-shapes.gif",
    logo: "bw-logo-mirvac.png",
  },
  {
    title: "First Home Deposit Saver",
    client: "ANZ",
    id: "anz",
    slug: "/anz",
    featuredImage: "anz-new-00002.jpg",
    logo: "bw-logo-anz.png",
  },
  {
    title: "Finding new markets and targets",
    client: "Standard Chartered Bank",
    id: "scb",
    slug: "/standard-chartered-bank",
    featuredImage: "scb-00001.jpg",
    logo: "bw-logo-scb.png",
  },
  {
    title: "Online Gender-Based Violence Framework",
    client: "World Wide Web Foundation",
    id: "www-f",
    slug: "/www-foundation",
    featuredImage: "www-foundation-workcard-full.png",
    logo: "wwwf-logos.png",
  },
  {
    title: "Homely Homes",
    client: "Anglicare",
    id: "homely-homes",
    slug: "/anglicare-homely-homes",
    featuredImage: "anglicare-thumb.png",
    logo: "anglicare.png",
  },
  // {
  //   title: "Driving Human Progress",
  //   client: "Ford",
  //   id: "d-ford",
  //   slug: "/d-ford",
  //   featuredImage: "d-ford-004.png",
  //
  //   logo: "ford-logo.png",
  // },
]

export const findWork = (id) => {
  return workList.find((work) => work.id === id)
}
