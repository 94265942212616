import React from 'react';

function IconAtsi() {
  return (
	<svg width="71" height="23" viewBox="0 0 71 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M33.3171 0.241211H0.902344V11.1917H33.3171V0.241211Z" fill="black"/>
		<path d="M33.3171 11.1053H0.902344V22.0559H33.3171V11.1053Z" fill="#E93324"/>
		<path d="M17.1501 16.9661C20.2436 16.9661 22.7514 14.4583 22.7514 11.3648C22.7514 8.27131 20.2436 5.76352 17.1501 5.76352C14.0566 5.76352 11.5488 8.27131 11.5488 11.3648C11.5488 14.4583 14.0566 16.9661 17.1501 16.9661Z" fill="#FFF850"/>
		<path d="M38.4841 22.1248L70.8989 22.1248V18.3765L38.4841 18.3765V22.1248Z" fill="#3B8525"/>
		<path d="M38.4841 18.4581L70.8989 18.4581V17.2227L38.4841 17.2227V18.4581Z" fill="#000002"/>
		<path d="M70.8992 5.06332H38.4844V17.1915H70.8992V5.06332Z" fill="#003FF5"/>
		<path d="M70.9021 0.241211H38.4873V3.98952H70.9021V0.241211Z" fill="#3B8525"/>
		<path d="M70.8992 3.90891H38.4844V5.14423H70.8992V3.90891Z" fill="#000002"/>
		<path d="M48.8877 16.7756L51.0755 14.9246L49.9999 11.4861C49.9999 11.4861 49.2629 8.67291 51.087 7.42411C52.9131 6.17339 54.4755 5.69812 54.4755 5.69812L54.9123 7.77239V9.2848C54.9123 9.2848 53.3364 9.20976 52.688 10.5471C52.6379 10.8973 52.6629 15.4595 52.6629 15.4595L48.8877 16.7756Z" fill="#FEFEFE"/>
		<path d="M60.8576 16.7756L58.6698 14.9246L59.7454 11.4861C59.7454 11.4861 60.4824 8.67291 58.6583 7.42411C56.8342 6.17532 55.2698 5.69812 55.2698 5.69812L54.833 7.77239V9.2848C54.833 9.2848 56.4089 9.20976 57.0574 10.5471C57.1074 10.8973 57.0824 15.4595 57.0824 15.4595L60.8576 16.7756Z" fill="#FEFEFE"/>
		<path d="M54.9187 9.66L55.3978 11.1359H56.9506L55.6941 12.0498L56.1751 13.5257L54.9187 12.6136L53.6622 13.5257L54.1413 12.0498L52.8867 11.1359H54.4395L54.9187 9.66Z" fill="#FEFEFE"/>
	</svg>
  )
}

export default IconAtsi;
