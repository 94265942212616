import { createGlobalStyle } from "styled-components"
import "typeface-karla"
import "typeface-lora"

const GlobalStyle = createGlobalStyle`
  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: ${(props) => props.theme.fluidType(0)};
      * { box-sizing: border-box; }
  }

  body {
      margin: 0;
      max-width: 100vw;
      overflow-x: hidden;
      // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
      font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      color: ${(props) => props.theme.colours.black};
      background-color: ${(props) => props.theme.colours.white};
  }

  h1, h2, h3, h4,
  .h1, .h2, .h3, .h4 {
      line-height: 1.1;
      margin-top: 1rem;
      margin-bottom: 1rem;
  }
  
  h5, h6,
  .h5, .h6,
  p, .p,
  ol, ul, li,
  code, kbd, pre, samp {
      line-height: 1.3;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      a { text-decoration: none; }
      a:hover { text-decoration: none; }
  }
  
  h1, .h1, h2, .h2, h3, .h3 { 
    font-family: "Lora", serif;
    font-weight: normal;
  }
  h1, .h1 { ${(props) => props.theme.fluidType(10)}; }
  h2, .h2 { ${(props) => props.theme.fluidType(6)}; }
  h3, .h3 { ${(props) => props.theme.fluidType(5)}; }
  h4, .h4 { ${(props) => props.theme.fluidType(4)}; }
  h5, .h5 { ${(props) => props.theme.fluidType(2)}; }
  h6, .h6 { ${(props) => props.theme.fluidType(0)}; }
  
  p, .p, ul, ol, blockquote { ${(props) => props.theme.fluidType(0)}; }

  li {
      ${(props) => props.theme.fluidType(0)};
      margin-top: 0;
      margin-bottom: 0;
  }

  .small,
  small {  
    ${(props) => props.theme.fluidType(-1)};
  }
  .smaller {  
    ${(props) => props.theme.fluidType(-2.5)};
    h1, h2, h3, h4, h5, h6, p, small,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      ${(props) => props.theme.fluidType(-2.5)};
    }
  }
  .smallest {  
    ${(props) => props.theme.fluidType(-3)};
    h1, h2, h3, h4, h5, h6, p, small,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      ${(props) => props.theme.fluidType(-3)};
      @media( ${(props) => props.theme.breakpoints.lg} ) {
        ${(props) => props.theme.fluidType(-4)};
      }
    }
    @media( ${(props) => props.theme.breakpoints.lg} ) {
      ${(props) => props.theme.fluidType(-4)};
    }
  }
  
  .lora, .serif {
    font-family: "Lora", serif;
    font-weight: normal;
  }
  
  .sans {
    font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  code, kbd, pre, samp {
      font-family: monospace;
      font-size: 16px;
      white-space: normal;
  }
  
  pre { font-size: 16px; }
  
  ul {
      padding-left: 4rem;
      list-style-type: disc;
  }
  
  ol {
      padding-left: 4rem;
      list-style-type: decimal;
  }
  
  video {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: bold; }
  
  blockquote {
    ${(props) => props.theme.fluidType(0)};
    font-family: "Lora", serif;
    font-weight: normal;
    padding: 0;
    margin: 4rem;
  }
  
  a { color: inherit; &:hover {color: unset;}}
  
  .pill {
    display: inline-block;
    font-weight: normal;
    line-height: 4rem;
    border-radius: 3rem;
    border: 1px solid;
    padding: 0 3rem;
    text-decoration: none;
    text-align: center;
  }
  
  .arrow-link { 
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
      transition: transform 0.25s ease-in-out;
      margin-right: 2rem;
      path {
        stroke: ${(props) => props.theme.colours.black};
      }
    }
    /* &:hover {
      svg {
        transform: translateX(0.5rem);
      }
    } */
    &.small {
      svg {
        width: 0.75rem;
        height: auto;
        margin-right: 1.5rem;
      }
    }
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
      ${(props) => props.theme.fluidType(-1)};
      line-height: 1.2;
      font-weight: normal;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${(props) => props.theme.fluidType(0)};
      padding: 0.4rem 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid;
      border-radius: 2px;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
          border: 1px ${(props) => props.theme.colours.focus} solid;
      }
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
      cursor: pointer;
  
      padding: 0 4rem;
  
      font-family:inherit;
      font-weight: bold;
      ${(props) => props.theme.fluidType(1)};
      line-height: 5rem;
      text-decoration: none;
      white-space: nowrap;
      border: none;
      border-radius: 0;
      text-transform: uppercase;
      text-align: center;
  
      color: inherit;
      background-color: ${(props) => props.theme.colours.orange};
      
      &.green { background-color: ${(props) => props.theme.colours.green}; }
      &.yellow { background-color: ${(props) => props.theme.colours.yellow}; }
      
      &:hover {
        text-decoration: none;
      }
    
    .strip-button-styles {
      background-color: inherit;
      font-size: inherit;
      padding: 0;
      margin: 0;
      border: none;
      line-height: inherit;
    }
      
      &.icon {
        margin: 0;
        border: none;
        width: auto;
        height: 3rem;
        padding: 0.1rem;
        background-color: transparent;
        svg {
          width: auto;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          display: block;
        }
      }
      &:focus { outline: none; }
  }
  
  .errorMsg {
      color: ${(props) => props.theme.colours.error};
  }

  ::selection {
    background-color: ${(props) => props.theme.colours.grey2};
    color: ${(props) => props.theme.colours.black};
    text-shadow: none;
  }

  .five-years {
    .splash-content h1{
      opacity: 0;
    }
    .splash-bg {
      background-image: url('/images/cw-5th-bday.inline.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: cover; 
      @media( ${(props) => props.theme.breakpoints.md} ) {
        background-attachment: fixed;
      }
    }
  }
  #xmas-owl {
    margin-left: -6px;
    transform: scale(0.95);
  }

`

export default GlobalStyle
