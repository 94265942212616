import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "../components/molecules/Header"
import Footer from "../components/molecules/Footer"
import Transition from "../components/atoms/Transition"

import GlobalStyles from "../components/atoms/GlobalStyles"
import "antd/dist/antd.css"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../utils/styling"
import Navigation from "../components/molecules/Navigation"
import smoothscroll from "smoothscroll-polyfill"
import Helmet from "react-helmet"

import faviconAbout from "../../static/images/favicon-about.png"
import faviconServices from "../../static/images/favicon-services.png"
import faviconJournal from "../../static/images/favicon-journal.png"
import faviconXmas from "../../static/images/favicon-xmas.png"
import faviconReg from "../../static/images/favicon.png"

const Main = styled.main`
  padding-top: ${(props) => (props.is_home ? "" : "2rem")};
`

function Index(props) {
  // dynamic favicon biz
  let favicon = faviconReg
  const path = props.location.pathname
  if (path.includes("about")) {
    favicon = faviconAbout
  } else if (path.includes("services")) {
    favicon = faviconServices
  } else if (path.includes("journal")) {
    favicon = faviconJournal
  } else if (path.includes("christmas") || path.includes("card")) {
    favicon = faviconXmas
  } else {
    favicon = faviconReg
  }

  const is_home = props.location.pathname === "/"

  const [showNav, setShowNav] = useState(false)

  const menuClickHandler = () => {
    smoothscroll.polyfill()
    if (window.scrollY) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" })
      setShowNav(true)
    } else {
      setShowNav(!showNav)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="shortcut icon" href={favicon} />
      </Helmet>
      <div id="top">
        <GlobalStyles />
        <Navigation showNav={showNav} />
        <Transition location={props.location}>
          <div id="scroll-holder">
            <Header
              showNav={showNav}
              clickHandler={() => menuClickHandler()}
              location={props.location}
            />
            <Main is_home={is_home}>{props.children}</Main>
          </div>
        </Transition>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Index
