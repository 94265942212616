import React from "react"
import styled from "styled-components"
import IconArrow from "../atoms/iconArrow"
import IconAtsi from "../atoms/iconAtsi"
import Pin from "../../assets/pin.inline.svg"
import PropTypes from "prop-types"

const Holder = styled.footer`
  position: relative;
  z-index: 10;
  color: ${(props) => props.theme.colours[props.color]};
  background-color: ${(props) => props.theme.colours.black};
`

const Contents = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 4rem 1rem 2rem 1rem;
  @media (${(props) => props.theme.breakpoints.xl}) {
    grid-template-columns: repeat(12, 1fr);
    padding: 10rem 0 3rem 0;
  }
`

const Acknowledgement = styled.div`
  display: flex;
  flex-direction: column;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-column: 1/6;
    flex-direction: column-reverse;
  }
  svg {
    width: 2.5rem;
    height: auto;
    display: block;
    margin: 0.5rem 0;
  }
  small {
    ${(props) => props.theme.fluidType(-1)};
  }
`

const Locations = styled.div`
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-column: 7/9;
  }
  .location {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  svg {
    width: auto;
    height: 0.8rem;
    display: inline;
    margin-right: 1rem;
  }
  h5 {
    color: ${(props) => props.theme.colours.white};
    font-weight: 600;
    margin: 0.5rem 0;
  }
  small {
    ${(props) => props.theme.fluidType(-1)};
  }
`

const Links = styled.div`
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-column: 9/11;
  }
  p {
    margin: 0;
  }
  a {
    margin-bottom: 0.5rem;
  }
  .arrow-link {
    svg {
      margin-right: 1rem;
      height: 0.8rem;
      width: auto;
      path {
        stroke: ${(props) => props.theme.colours[props.color]};
      }
    }
  }
`

const Phone = styled.div`
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-column: 11/13;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

function Footer({ color }) {
  return (
    <Holder color={color} className="smallest">
      <Contents>
        <Acknowledgement>
          <IconAtsi />
          In the spirit of reconciliation Craig Walker acknowledges the
          Traditional Custodians of country throughout Australia and their
          connections to land, sea and community. We pay our respect to their
          elders past and present and extend that respect to all Aboriginal and
          Torres Strait Islander peoples&nbsp;today.
        </Acknowledgement>

        <Locations>
          <h5 className="location">
            <Pin />
            <span>Sydney</span>
          </h5>
          <h5 className="location">
            <Pin />
            <span>Melbourne</span>
          </h5>
          <h5 className="location">
            <Pin />
            <span>Singapore</span>
          </h5>
        </Locations>

        <Links color={color}>
          <p>
            <a
              className="arrow-link smallest"
              href="mailto:hello@craigwalker.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconArrow />
              <span>Email</span>
            </a>
            <a
              className="arrow-link smallest"
              href="https://www.linkedin.com/company/craig-walker/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconArrow />
              <span>Linkedin</span>
            </a>
            <a
              className="arrow-link smallest"
              href="https://www.instagram.com/craigwalkerstudio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconArrow />
              <span>Instagram</span>
            </a>
            <a
              className="arrow-link smallest"
              href="https://twitter.com/crgwlkrstdio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconArrow />
              <span>Twitter</span>
            </a>
          </p>
        </Links>
        <Phone>
          <p>
            {/* <a href="tel:61292120603">+61 29 212 0603</a><br/> */}
            <a href="mailto:hello@craigwalker.com.au">
              hello@craigwalker.com.au
            </a>
          </p>
          <p>&copy; Craig Walker {new Date().getFullYear()}</p>
        </Phone>
      </Contents>
    </Holder>
  )
}

Footer.propTypes = {
  color: PropTypes.string,
}

Footer.defaultProps = {
  color: "white",
}

export default Footer
