import React from 'react';

function IconArrow() {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.3584 2L17.3584 12L8.35839 22" stroke="white" strokeWidth="3"/>
      <path d="M16.5 12L0 12" stroke="white" strokeWidth="3"/>
    </svg>

  )
}

export default IconArrow;
