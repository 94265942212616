import React, {useEffect, useRef} from "react"
import Logo from "../atoms/Logo"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
import styled from "styled-components"
import PropTypes from "prop-types"

gsap.registerPlugin(ScrollTrigger)

const Holder = styled.div`
  #animated-logo {
    display: flex;
    align-items: center;
  }

  svg {
    overflow: visible;
  }
`
const ClientLogo = styled.div`
  height: 3rem;
  padding: 0 0.4rem 0 1.4rem;
  border-left: 1px solid;
  margin-left: 1rem;
  display: flex;
  align-items: center;

  img {
    height: 1.5rem;
    object-fit: contain;
  }

`

const ClientLogoWrapper = styled.div`
  display: none;
  @media (${(props) => props.theme.breakpoints.md}) {
    display: block;
    width: 100%;
  }
`


function AnimatedLogo({clientLogo}) {
  const holder = useRef(null)
  const tl = useRef(null)

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: "body",
            start: "top top",
            end: "top -10%",
            scrub: true,
          },
        })
        .to(`#animated-logo`, {
          autoAlpha: 0,
          duration: 2,
        })
    }
  })

  return (
    <Holder ref={holder}>
      <div id="animated-logo">
        <Logo />
        <ClientLogoWrapper>
          {clientLogo && (
            <ClientLogo className="client-logo">
              <img src={`/images/${clientLogo}`} alt="" />
            </ClientLogo>
          )}
        </ClientLogoWrapper>
      </div>
    </Holder>
  )
}

AnimatedLogo.propTypes = {
  clientLogo: PropTypes.string,
}

export default AnimatedLogo
